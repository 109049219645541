import i18n from '@/plugins/i18n'
export default 
    {
        main: {
            dashboard: i18n.t('report.dashboard')            ,
            report: i18n.t('reporttxt'),
            inventory: i18n.t('management.inventory'),
            shop: i18n.t('management.shopBranch'),
            user: i18n.t('management.user'),
            member:i18n.t('member.txtmember'),
            loyaltyPoint:i18n.t('member.txtmember'),
          },
          inventory: {
            stockIn: i18n.t('management.stockIn'),
            stockOut: i18n.t('management.stockOut'),
            adjustStock: i18n.t('management.adjustStock'),
            transfer: i18n.t('management.transferStock'),
            sku: i18n.t('management.reportBySKU'),
            plu: i18n.t('management.reportByPLU'),
            stockcard: i18n.t('management.stockMovemen'),
          },
          report: {
            transaction: i18n.t('report.transaction'),
            currentbill: i18n.t('report.currentbill'),
            daily: i18n.t('report.daily'),
            sell: i18n.t('report.unsell'),
            sku: i18n.t('report.sku'),
            plu: i18n.t('report.plu'),
            unsell: i18n.t('report.unsell'),
            cashier: i18n.t('report.byCashier'),
            category: i18n.t('report.byCategory'),
            payment: i18n.t('report.paymentreport'),
            tax: i18n.t('report.salesTax'),
            closesell: i18n.t('report.closedSale'),
            voidbill: i18n.t('report.voidbill'),
            timesheet: i18n.t('report.timesheet'),
            dailyOrder: i18n.t('report.salesByOrder'),
            sellOrder: i18n.t('report.productByOrder'),
            voidBillOrder: i18n.t('report.unsalesByOrder'),
            noadjust: i18n.t('report.nonadjust'),
            optional: i18n.t('report.optionalsales')
          },
          product: {
            // list: i18n.t(''),
            // edit: i18n.t(''), //add
            // delete: i18n.t(''),
            // category: i18n.t(''),
            // categoryEdit: i18n.t(''),
            // categoryDelete: i18n.t(''),
            // unit: i18n.t(''),
            // unitEdit: i18n.t(''),
            // unitDelete: i18n.t(''),
          },
          excel: {
            // reportTransaction: i18n.t(''),
            // reportDaily: i18n.t(''),
            // reportSell: i18n.t(''),
            // reportSku: i18n.t(''),
            // reportPlu: i18n.t(''),
            // reportUnsell: i18n.t(''),
            // reportCashier: i18n.t(''),
            // reportCategory: i18n.t(''),
            // reportPayment: i18n.t(''),
            // reportTax: i18n.t(''),
            // reportClosesell: i18n.t(''),
            // reportVoidbill: i18n.t(''),
            // reportTimesheet: i18n.t(''),
            // reportDailyOrder: i18n.t(''),
            // reportSellOrder: i18n.t(''),
            // reportVoidBillOrder: i18n.t(''),
            // reportNoadjust: i18n.t(''),
            // inventorySku: i18n.t(''),
            // inventoryPlu: i18n.t(''),
          },
    }
  ;
  