<template>
  <div v-if="isOwner" id="permissionContainer">
    <CRow class="align-items-center" id="permissionHeaderRow">
      <CCol md="2" class="ml-auto" id="permissionHeaderCol">
        <div v-if="!isDetail" id="permissionBackButtonContainer">
          <CButton block color="light" id="permissionBackButton" @click="isDetail = true" class="text-center">
            <i class="fi fi-rr-arrow-left mr-2" ></i>{{ $t("back") }}
          </CButton>
        </div>
        <div v-else id="permissionAddNewButtonContainer">
          <CButton
            block
            color="success"
            v-on:click="addNewModal()"
            v-if="loadingButton === true"
            id="permissionAddNewButton"
            style="display: flex; align-items: center; justify-content: center"
          >
            <i class="fi fi-rr-plus" style="margin-right: 8px"></i> {{ $t("newUser") }}
          </CButton>
          <CButton block color="success" disabled v-else id="permissionLoadingButton">
            <CSpinner color="white" size="sm" id="permissionLoadingSpinner"/> {{ $t("loading") }}
          </CButton>
        </div>
      </CCol>
    </CRow>
    <CRow class="align-items-start" id="permissionTitleRow">
      <CCol md="12" class="text-center" id="permissionTitleCol">
        <div class="d-inline-flex align-items-start" id="permissionTitleContainer">
          <h2 class="font-weight-medium text-dark" id="permissionTitleText">
            {{ $t("permissionManage") }}
          </h2>
          <HelpButton :isHeader="false" class="ml-2 mt-2" id="permissionHelpButton"></HelpButton>
        </div>
        <h6 class="text-description" id="permissionDescription">
          {{ $t("accessPermissionDes") }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    <div v-if="isDetail" class="mt-4" id="permissionDetailSection">
      <div class="row" id="permissionDetailRow">
        <div class="col-md-10 col-7" id="permissionDetailTitleContainer">
          <h4 class="font-weight-medium" id="permissionDetailTitle">
            <i class="fi fi-rr-user-lock"></i> {{ $t("permissionManage") }}
          </h4>
        </div>
        <!-- <div class="col-5 col-sm-2 text-right text-success">
          <CButton
            block
            color="success"
            v-on:click="addNewModal()"
            v-if="loadingButton === true"
            style="display: flex; align-items: center; justify-content: center;"
          >
          <i class="fi fi-rr-plus" style="margin-right: 8px;"></i> {{ $t('newUser') }}
          </CButton>
          <CButton block color="success" disabled v-else>
            <CSpinner color="white" size="sm" /> {{ $t('loading') }}
          </CButton>
        </div> -->
      </div>
      <div class="mt-4" id="permissionListContainer">
        <div class="list-group" id="permissionListGroup">
          <button
            v-for="(item, index) in items"
            :key="index"
            @click="setPermission(item)"
            class="list-group-item list-group-item-action"
            :disabled="item.isOwner === true"
            :id="`permissionListItem-${index}`"
          >
            <table style="width: 100%; border: none" :id="`permissionListTable-${index}`">
              <tr>
                <td style="width: 70%" :id="`permissionListItemEmail-${index}`">
                  <h5 class="mb-1 text-dark font-weight-medium">
                    {{ item.email }}
                  </h5>
                </td>
                <td :id="`permissionListItemLastUpdated-${index}`">
                  <small v-if="item.isOwner !== true" style="float: right">
                    {{ $t("lastUpdate") }} : {{ item.lastUpdated }}
                  </small>
                </td>
              </tr>
              <tr>
                <td :id="`permissionListItemRole-${index}`">
                  <p class="mb-1 font-weight-medium" v-if="item.isOwner === true">
                    owner
                  </p>
                  <p class="mb-1" v-else>
                    {{ item.name }}
                  </p>
                </td>
                <td class="text-right" :id="`permissionListItemArrow-${index}`">
                  <small><i class="fi fi-rr-angle-small-right"></i></small>
                </td>
              </tr>
            </table>
          </button>
        </div>
      </div>
    </div>
    <div v-else id="permissionEditSection">
      <div class="row" id="permissionEditRow">
        <div id="permissionEditHeader" class="col-12 d-flex justify-content-between align-items-center">
          <h4 class="font-weight-medium" id="permissionEditEmail">
            Email: {{ itemDetail.email }}
            </h4>
            <img
              src="/img/trash.png"
              style="width: 20px; height: 20px; color: red; cursor: pointer"
              v-c-tooltip="{
                content: 'Delete user',
                placement: 'right',
              }"
              @click="deleteModal = true"
              id="permissionDeleteIcon"
            />
        </div>
        <div class="col-12" id="permissionEditName">
          <p>Name: {{ itemDetail.name }}</p>
        </div>
      </div>
      <!-- :items = permission -->
      <set-permission
        v-bind:permission="permission"
        v-if="isDetail == false"
        id="permissionSetPermissionComponent"
      ></set-permission>
      <CRow class="justify-content-end mt-4" id="permissionSaveCancelRow">
        <CCol lg="2" col="6">
          <CButton
            style="
              background-color: white;
              font-weight: normal;
              margin-right: 15px;
              box-shadow: 0 0 0 1px #e5e5e5;
            "
            class="btn-block"
            @click="isDetail = true"
            square
            id="permissionCancelButton"
          >
            {{ $t("cancel") }}
          </CButton>
        </CCol>
        <CCol lg="2" col="6" id="permissionSaveCol">
          <CButton
            color="success"
            block
            v-if="loadingButton === true"
            @click="confirmModal = true"
            id="permissionSaveButton"
          >
            {{ $t("save") }}
          </CButton>
          <CButton  id="permissionSaveLoadingButton" block color="success" v-else-if="loadingButton === false" disabled>
            <CSpinner color="white" size="sm" />{{ $t("save") }}
          </CButton>
        </CCol>
      </CRow>
    </div>
    <CModal :show.sync="addUserModal" centered :title="$t('addUser')" id="permissionAddUserModal">
      <p class="text-danger font-weight-medium text-left">
        {{ validateAlert }}
      </p>
      <p class="text-danger font-weight-medium text-left" id="permissionAddUserErrors">{{ errors }}</p>
      <CRow id="permissionAddUserModalRow">
        <CCol id="permissionAddUserModalCol" md="12" col="12">
          <div class="form-group row">
            <label id="permissionAddUserModalLabelUserType" class="col-sm-3 col-3 col-form-label text-dark">
              {{ $t("userType") }} <b class="text-danger">*</b>
            </label>
            <div id="permissionAddUserModalSelect" class="col-9 col-lg-9 col-sm-9">
              <select class="custom-select" v-model="user.userType" disabled>
                <option selected value="MANAGER">Manager</option>
                <option selected value="FRANCHISE">Franchise</option>
              </select>
            </div>
          </div>
        </CCol>
        <CCol md="12" col="12" id="permissionEmailSection">
          <CRow id="permissionEmailRow">
            <CCol md="9" col="9" id="permissionEmailInputCol">
              <div class="form-group row" id="permissionEmailFormGroup">
                <label id="permissionEmailLabel" class="col-sm-4 col-3 col-form-label text-dark">
                  {{ $t("email") }} <b class="text-danger">*</b>
                </label>
                <div class="col-sm-8 col-9" id="permissionEmailInputContainer">
                  <input
                    @input="checkValidEmail()"
                    class="form-control text-black"
                    v-model="user.mail"
                    id="permissionEmailInput"
                  />
                  <small v-if="isvalidEmail" class="text-danger" id="permissionEmailValidationError">
                    {{ $t("validateEmail") }}
                  </small>
                </div>
              </div>
            </CCol>
            <CCol md="3" col="3" v-if="isLoadingCheckValid" id="permissionEmailLoadingCol">
              <CButton block color="primary" v-on:click="checkValidEmail()" id="permissionEmailLoadingButton">
                <CSpinner color="white" size="sm" id="permissionEmailLoadingSpinner"/> {{ $t("loading") }}
              </CButton>
            </CCol>
            <CCol md="3" col="3" v-else id="permissionEmailActionCol">
              <CButton
                block
                color="primary"
                v-on:click="checkValidEmail()"
                v-if="emailIsValid"
                id="permissionEmailValidButton"
              >
                <i class="fi fi-rr-check"></i>
              </CButton>
              <CButton block color="primary" v-on:click="checkValidEmail()" v-else id="permissionEmailVerifyButton">
                {{ $t("verify") }}
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="12" col="12" v-if="isPassWord" id="permissionPasswordSection">
          <div class="form-group row" id="permissionPasswordFormGroup">
            <label class="col-sm-3 col-3 col-form-label text-dark" id="permissionPasswordLabel">
              {{ $t("password") }} <b class="text-danger">*</b>
            </label>
            <div class="col-sm-9 col-9" id="permissionPasswordInputContainer">
              <VuePassword
                v-model="user.pass"
                key=""
                disableStrength
                password-show
                minlength="6"
                type="password"
                id="permissionPasswordInput"
              />
              <span style="font-size: small" v-if="isValidlenght" class="text-danger" id="permissionPasswordValidationError">
                * {{ $t("passAtless") }}
              </span>
            </div>
          </div>
        </CCol>
        <CCol md="12" col="12" v-if="isPassWord" id="permissionConfirmPasswordSection">
          <div class="form-group row" id="permissionConfirmPasswordFormGroup">
            <label class="col-sm-3 col-3 col-form-label text-dark" id="permissionConfirmPasswordLabel">
              {{ $t("confirmPassword") }} <b class="text-danger">*</b>
            </label>
            <div class="col-sm-9 col-9" id="permissionConfirmPasswordInputContainer">
              <VuePassword
                v-model="user.validpassword"
                @input="checkpass"
                password-show
                disableStrength
                minlength="6"
                type="password"
                id="permissionConfirmPasswordInput"
              />
              <span style="font-size: small" v-if="isValit" class="text-danger" id="permissionConfirmPasswordValidationError">
                * {{ $t("inValidPass") }}
              </span>
            </div>
          </div>
        </CCol>
      </CRow>

      <template #footer id="permissionAddUserModalFooter">
        <CRow class="justify-content-around col-md-12">
          <CCol col="4 " v-if="emailIsValid" id="permissionAddUserModalFooterEmailIsValid">
            <CButton
              color="success"
              block
              v-if="loadingButton === true"
              v-on:click="validate()"
              id="permissionAddUserModalFooterEmailIsValidBtn"
            >
              {{ $t("save") }}
            </CButton>
            <CButton id="permissionAddUserModalFooterEmailIsValidLoading" block color="success" v-else-if="loadingButton === false" disabled>
              <CSpinner color="white" size="sm" />{{ $t("save") }}
            </CButton>
          </CCol>
          <CCol v-else col="4 ">
            <CButton id="permissionAddUserModalFooterDisabledSave" color="success" block disabled>
              {{ $t("save") }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton id="permissionAddUserModalFooterCancelBtn" block color="light" @click="addUserModal = false">
              {{ $t("cancel") }}
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
    <modal
      color="danger"
      :show.sync="deleteModal"
      :title="$t('deleteUser')"
      v-on:isSave="deleteAccess"
      centered
      :loading="loading"
      id="permissionDeleteUserModal"
    >
      <br />
      <h4 class="text-center" id="permissionDeleteUserTitle">{{ $t("confirmDeleteUser") }}</h4>
      <br />
    </modal>
    <modal
      color="success"
      :show.sync="confirmModal"
      :title="$t('confirmUpdateInformation')"
      v-on:isSave="savedata"
      centered
      :loading="loading"
      id="permissionConfirmUpdateModal"
    >
      <br />
      <h4 class="text-center" id="permissionConfirmUpdateTitle">{{ $t("confirmUpdateUserInfo") }}</h4>
      <br />
    </modal>
    <modal
      color="success"
      :show.sync="confirmAddModal"
      :title="$t('confirmUpdateInformation')"
      v-on:isSave="addNew"
      centered
      :loading="loading"
      id="permissionConfirmAddUserModal"
    >
      <br />
      <h4 class="text-center" id="permissionConfirmAddUserTitle">{{ $t("confirmUpdateUserInfo") }}</h4>
      <br />
    </modal>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import pos from "@/services/local";
import auth from "@/services/auth";
import { mapGetters } from "vuex";
import moment from "moment";
import SetPermission from "./SetPermission";
import _text from "@/containers/_permission";
import VuePassword from "vue-password";
import permis from "@/util/permission";
import HelpButton from "../../containers/HelpButton.vue";

export default {
  components: {
    SetPermission,
    VuePassword,
    HelpButton,
  },
  data() {
    return {
      loadingButton: true,
      searchLoadingButton: true,
      loading: true,
      keyword: "",
      popupModal: false,
      data: [],
      text: _text,
      permission: {},
      itemDetail: {},
      addUserModal: false,
      user: {
        mail: "",
        pass: "",
        validpassword: "",
        userType: "MANAGER",
      },
      validateAlert: "",
      errors: "",
      isValit: false,
      isvalidEmail: false,
      isValidlenght: false,
      deleteModal: false,
      isDetail: true,
      confirmModal: false,
      confirmAddModal: false,
      emailIsValid: false,
      isPassWord: false,
      isLoadingCheckValid: false,
    };
  },
  mounted() {
    this.$store.dispatch("getUser");
    this.$store.dispatch("getPermissionSetting");
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "permissionSetting", "isOwner"]),
    isPermission() {
      return permis.findPermissionRead("setting", this.$route.path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    items() {
      let data = this.permissionSetting;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        let email = "";
        let name = "";
        if (data[i].User != undefined) {
          if (data[i].User.authData.email != undefined) {
            email = data[i].User.authData.email;
          }
          if (data[i].User.authData.displayName != undefined) {
            name = data[i].User.authData.displayName;
          }
        }

        detail.push({
          email: email,
          name: name,
          uid: data[i].uid,
          isOwner: data[i].isOwner,
          shopObjectId: data[i].shopObjectId,
          permission: data[i].permission,
          lastUpdated: moment(String(data[i].updated_at)).format("DD/MM/YYYY"),
        });
      }

      return detail;
    },
  },
  methods: {
    checkValidEmail() {
      if (!this.validEmail(this.user.mail)) {
        this.isvalidEmail = true;
        this.emailIsValid = false;
        this.isPassWord = false;
      } else {
        this.isvalidEmail = false;
        this.isLoadingCheckValid = true;
        const params = {
          email: this.user.mail,
        };
        auth.get("/auth/v1.0/checkuser/email", { params }).then((res) => {
          this.emailIsValid = true;

          if (res.data.error.code === 0) {
            this.isPassWord = false;
          } else {
            this.isPassWord = true;
          }
          this.isLoadingCheckValid = false;
        });
      }
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return EMAILREG.test(email);
    },
    validPassword(pass) {
      if (pass.pass === pass.validpassword) {
        return true;
      } else {
        return false;
      }
    },
    addNewModal() {
      this.user = {
        mail: "",
        pass: "",
        validpassword: "",
        userType: "MANAGER",
      };
      this.isvalidEmail = false;
      this.emailIsValid = false;
      this.isLoadingCheckValid = false;
      this.isPassWord = false;
      this.addUserModal = true;
    },
    checkpass() {
      if (this.user.pass.length < 6) {
        this.isValidlenght = true;
        this.isValit = false;
      } else if (!this.validPassword(this.user)) {
        this.isValit = true;
        this.isValidlenght = false;
      } else {
        this.isValidlenght = false;
        this.isValit = false;
      }
    },
    validate() {
      const isPassWord = this.isPassWord;
      if (isPassWord) {
        if (
          this.user.mail === "" ||
          this.user.pass === "" ||
          this.user.validpassword === "" ||
          this.user.userType === ""
        ) {
          this.validateAlert = this.$i18n.t("validateInfomation");
        } else {
          this.validateAlert = "";
          if (!this.validEmail(this.user.mail)) {
            this.isvalidEmail = true;
          } else if (this.user.pass.length < 6) {
            this.isvalidEmail = false;
            this.isValidlenght = true;
            this.isValit = false;
          } else if (!this.validPassword(this.user)) {
            this.isValit = true;
            this.isValidlenght = false;
          } else {
            this.isValidlenght = false;
            this.isValit = false;
            this.isvalidEmail = false;
            this.validateAlert = "";
            this.addUserModal = false;
            this.confirmAddModal = true;
            this.errors = "";
          }
        }
      } else {
        if (this.user.mail === "" || this.user.userType === "") {
          this.validateAlert = this.$i18n.t("validateInfomation");
        } else {
          this.validateAlert = "";
          if (!this.validEmail(this.user.mail)) {
            this.isvalidEmail = true;
          } else {
            this.isValidlenght = false;
            this.isValit = false;
            this.isvalidEmail = false;
            this.validateAlert = "";
            this.addUserModal = false;
            this.confirmAddModal = true;
            this.errors = "";
          }
        }
      }
    },
    addNew() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const isPassWord = this.isPassWord;
      let data = {};
      if (isPassWord) {
        data = {
          email: this.user.mail,
          shopObjectId: this.shopObjectId,
          password: this.user.pass,
          userType: this.user.userType,
        };
      } else {
        data = {
          email: this.user.mail,
          shopObjectId: this.shopObjectId,
          userType: this.user.userType,
        };
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      this.loadingButton = false;
      this.loading = false;
      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/Shop/invite",
        data: data,
        headers: headers,
      })
        .then(() => {
          this.$store.dispatch("getPermission");
          this.$store.dispatch("getPermissionSetting");
          this.loadingButton = true;
          this.confirmAddModal = false;
          this.addUserModal = false;
          this.loading = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savedata() {
      const uid = this.uid;
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      const data = {
        uid: this.itemDetail.uid,
        shopObjectId: this.itemDetail.shopObjectId,
        permission: this.permission,
      };
      this.loading = false;
      this.loadingButton = false;
      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/Shop/access/permission/update",
        data: data,
        headers: headers,
      })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("getPermission");
            this.$store.dispatch("getPermissionSetting");
            this.loading = true;
            this.confirmModal = false;
            this.loadingButton = true;
            this.popupModal = false;
            this.isDetail = true;
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPermission(item) {
      this.itemDetail = item;
      this.permission = item.permission;
      this.isDetail = false;
    },
    deleteAccess() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let data = {
        uid: this.itemDetail.uid,
        shopObjectId: this.itemDetail.shopObjectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      this.loading = false;
      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/Shop/deleteaccess",
        data: data,
        headers: headers,
      })
        .then(() => {
          this.$store.dispatch("getPermission");
          this.$store.dispatch("getPermissionSetting");
          this.loading = true;
          this.confirmModal = false;
          this.loadingButton = true;
          this.popupModal = false;
          this.isDetail = true;
          this.deleteModal = false;
          this.popupModal = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    shopObjectId() {
      this.isDetail = true;
    },
  },
};
</script>

<style>
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3abc98;
  background-color: white;
  border-color: #3abc98 #3abc98 #ffffff;
}
a.nav-link {
  color: gray;
}
a.nav-link:hover {
  color: #3abc98;
  border-color: #ffffff;
}
li:hover {
  cursor: pointer;
}
</style>
