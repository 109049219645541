<template>
  <CCard border-color="light" id="setPermisCard">
    <CCardBody id="setPermisCardBody">
      <CTabs justified fill id="setPermisTabs">
        <template v-for="(item, key, index) in permission">
          <CTab
            v-if="item.lock === false"
            :key="index"
            :title="$t(key + 'Menu')"
            active
            :id="`setPermisTab-${index}`"
          >
            <table style="width:100%" class="mt-4" :id="`setPermisTable-${index}`">
              <tr class="font-weight-normal h5" valign="top" :id="`setPermisRow-${index}`">
                <td
                  style="width:5%; background-color:#41b883; border-radius: 8px;"
                  class="text-white text-center p-2"
                  :id="`setPermisIconCell-${index}`"
                >
                  <img :src="getImageSrc(index)" alt="icon" style="width: 48px; height: 48px;" :id="`setPermisIcon-${index}`"/>
                </td>

                <td style="width:65%" class="pl-2" :id="`setPermisTitleCell-${index}`">
                  <h5 class="text-left font-weight-normal" :id="`setPermisTitle-${index}`">
                    {{ $t(key + 'Menu') }}
                  </h5>
                </td>
                <td
                  v-if="item.enable === true"
                  class="text-right text-success pr-2"
                  :id="`setPermisEnableText-${index}`"
                >
                  {{ $t('enable') }}
                </td>
                <td
                  v-if="item.enable === false"
                  class="text-right text-danger pr-2"
                  :id="`setPermisDisableText-${index}`"
                >
                  {{ $t('disable') }}
                </td>
                <td class="text-right font-weight-normal" style="width:5%" :id="`setPermisSwitchCell-${index}`">
                  <CSwitch
                    v-model="item.enable"
                    color="success"
                    :value="item.enable"
                    :checked.sync="item.enable"
                    :id="`setPermisSwitch-${index}`"
                  />
                </td>
              </tr>
            </table>

            <div v-if="item.enable == true" class="mt-4" :id="`setPermisMenuContainer-${index}`">
              <table class="table table-bordered" v-if="item.menu.length > 0" :id="`setPermisMenuTable-${index}`">
                <thead id="setPermisMenuTableHead">
                  <tr>
                    <!-- <th style="width:30%; font-size: 15px; font-weight: normal;">{{ $t('menu') }}</th> -->
                    <th></th>
                    <th class="text-center">
                      <h6
                        class="text-success mb-0"
                        style="font-size: 12px; font-weight: normal;"
                        id="setPermisMenuShowHeader"
                      >
                        {{ $t('show') }}
                      </h6>
                    </th>
                    <th class="text-center">
                      <h6
                        class="text-warning mb-0"
                        style="font-size: 12px;font-weight: normal;"
                        id="setPermisMenuAddEditHeader"
                      >
                        {{ $t('add') }}/{{ $t('edit') }}
                      </h6>
                    </th>
                    <th class="text-center">
                      <h6
                        class="text-danger mb-0"
                        style="font-size: 12px;font-weight: normal;"
                        id="setPermisMenuDeleteHeader"
                      >
                        {{ $t('delete') }}
                      </h6>
                    </th>
                    <th class="text-center">
                      <h6
                        class="text-info mb-0"
                        style="font-size: 12px;font-weight: normal;"
                        id="setPermisMenuExportHeader"
                      >
                        {{ $t('export') }}
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody :id="`setPermisMenuTableBody-${index}`">
                  <template v-for="(menu, menuIndex) in item.menu">
                    <tr v-if="menu.lock === false" :key="menuIndex" :id="`setPermisMenuRowTab-${index}Row-${menuIndex}`">
                      <td class="text-dark" :id="`setPermisMenuLabelTab-${index}Row-${menuIndex}`">{{ $t(menu.label) }}</td>
                      <td class="text-center font-weight-normal" :id="`setPermisMenuReadCellTab-${index}Row-${menuIndex}`">
                        <CSwitch
                          v-if="menu.read !== undefined"
                          v-model="menu.read"
                          labelOn="on"
                          labelOff="off"
                          color="success"
                          shape="pill"
                          size="sm"
                          :checked.sync="menu.read"
                          :id="`setPermisMenuReadSwitchTab-${index}Row-${menuIndex}`"
                        />
                        <CSwitch
                          v-else
                          v-model="menu.read"
                          labelOn="on"
                          labelOff="off"
                          color="success"
                          shape="pill"
                          size="sm"
                          disabled
                          :id="`setPermisMenuReadSwitchDisabledTab-${index}Row-${menuIndex}`"
                        />
                      </td>
                      <td class="text-center" :id="`setPermisMenuEditCell-${index}-${menuIndex}`">
                        <CSwitch
                          v-if="menu.edit !== undefined"
                          v-model="menu.edit"
                          labelOn="on"
                          labelOff="off"
                          color="warning"
                          shape="pill"
                          size="sm"
                          :checked.sync="menu.edit"
                          :id="`setPermisMenuEditSwitchTab-${index}Row-${menuIndex}`"
                        />
                        <CSwitch
                          v-else
                          v-model="menu.edit"
                          labelOn="on"
                          labelOff="off"
                          color="warning"
                          shape="pill"
                          size="sm"
                          disabled
                          :id="`setPermisMenuEditSwitchDisabledTab-${index}Row-${menuIndex}`"
                        />
                      </td>
                      <td class="text-center" :id="`setPermisMenuDeleteCellTab-${index}Row-${menuIndex}`">
                        <CSwitch
                          v-if="menu.remove !== undefined"
                          v-model="menu.remove"
                          labelOn="on"
                          labelOff="off"
                          color="danger"
                          shape="pill"
                          size="sm"
                          :checked.sync="menu.remove"
                          :id="`setPermisMenuDeleteSwitchTab-${index}Row-${menuIndex}`"
                        />
                        <CSwitch
                          v-else
                          v-model="menu.remove"
                          labelOn="on"
                          labelOff="off"
                          color="danger"
                          shape="pill"
                          size="sm"
                          disabled
                          :id="`setPermisMenuDeleteDisabledSwitchTab-${index}Row-${menuIndex}`"
                        />
                      </td>
                      <td class="text-center" :id="`setPermisMenuExportCellTab-${index}Row-${menuIndex}`">
                        <CSwitch
                          v-if="menu.export !== undefined"
                          v-model="menu.export"
                          labelOn="on"
                          labelOff="off"
                          color="info"
                          shape="pill"
                          size="sm"
                          :checked.sync="menu.export"
                          :id="`setPermisMenuExportSwitchTab-${index}Row-${menuIndex}`"
                        />
                        <CSwitch
                          v-else
                          v-model="menu.export"
                          labelOn="on"
                          labelOff="off"
                          color="info"
                          shape="pill"
                          size="sm"
                          disabled
                          :id="`setPermisMenuExportSwitchDisabledTab-${index}Row-${menuIndex}`"
                        />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <table class="table table-bordered" v-else id="setPermisTable">
                <thead id="setPermisTableHead">
                  <tr id="setPermisTableHeaderRow">
                    <!-- <th style="width:30%; font-size: 15px; font-weight: normal;" >{{ $t('menu') }}</th> -->
                    <th></th>
                    <th class="text-center" id="setPermisTableHeaderShow">
                      <h6
                        class="text-success mb-0"
                        style="font-size: 12px; font-weight: normal;"
                      >
                        {{ $t('show') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderAddEdit">
                      <h6
                        class="text-warning mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('add') }}/{{ $t('edit') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderDelete">
                      <h6
                        class="text-danger mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('delete') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderExport">
                      <h6
                        class="text-info mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('export') }}
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody id="setPermisTableBody">
                  <tr v-if="item.lock === false" id="setPermisTableRow">
                    <td class="text-left text-dark" id="setPermisTableLabel">
                      {{ $t(item.label + 'Menu') }}
                    </td>
                    <td class="text-center" id="setPermisTableShowSwitch">
                      <CSwitch
                        v-if="item.read !== undefined"
                        v-model="item.read"
                        labelOn="on"
                        labelOff="off"
                        color="success"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.read"
                      />
                      <CSwitch
                        v-else
                        v-model="item.read"
                        labelOn="on"
                        labelOff="off"
                        color="success"
                        shape="pill"
                        size="sm"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableAddEditSwitch">
                      <CSwitch
                        v-if="item.edit !== undefined"
                        v-model="item.edit"
                        labelOn="on"
                        labelOff="off"
                        color="warning"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.edit"
                      />
                      <CSwitch
                        v-else
                        v-model="item.edit"
                        labelOn="on"
                        labelOff="off"
                        color="warning"
                        shape="pill"
                        size="sm"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableDeleteSwitch">
                      <CSwitch
                        v-if="item.remove !== undefined"
                        v-model="item.remove"
                        labelOn="on"
                        labelOff="off"
                        color="danger"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.remove"
                      />
                      <CSwitch
                        v-else
                        v-model="item.remove"
                        labelOn="on"
                        labelOff="off"
                        color="danger"
                        shape="pill"
                        size="sm"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableExportSwitch">
                      <CSwitch
                        v-if="item.export !== undefined"
                        v-model="item.export"
                        labelOn="on"
                        labelOff="off"
                        color="info"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.export"
                      />
                      <CSwitch
                        v-else
                        v-model="item.export"
                        labelOn="on"
                        labelOff="off"
                        color="info"
                        shape="pill"
                        size="sm"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="mt-4" id="setPermisContainer">
              <table class="table table-bordered" v-if="item.menu.length > 0" id="setPermisTableWithMenu">
                <thead id="setPermisTableHead">
                  <tr id="setPermisTableHeaderRow">
                    <!-- <th style="width:30%; font-weight: normal; font-size: 15px;">{{ $t('menu') }}</th> -->
                    <th></th>
                    <th class="text-center" id="setPermisTableHeaderShow">
                      <h6
                        class="text-success mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('show') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderAddEdit">
                      <h6
                        class="text-warning mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('add') }}/{{ $t('edit') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderDelete">
                      <h6
                        class="text-danger mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('delete') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableHeaderExport">
                      <h6
                        class="text-info mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('export') }}
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody id="setPermisTableBody">
                  <tr v-for="(menu, index) in item.menu" :key="index" :id="`setPermisTableRow-${index}`">
                    <td class="text-dark" :id="`setPermisMenuLabel-${index}`">{{ $t(menu.label) }}</td>
                    <td class="text-center" :id="`setPermisMenuShowSwitch-${index}`">
                      <CSwitch
                        v-model="menu.read"
                        labelOn="on"
                        labelOff="off"
                        color="success"
                        shape="pill"
                        size="sm"
                        :checked.sync="menu.read"
                        disabled
                      />
                    </td>
                    <td class="text-center" :id="`setPermisMenuAddEditSwitch-${index}`">
                      <CSwitch
                        v-model="menu.edit"
                        labelOn="on"
                        labelOff="off"
                        color="warning"
                        shape="pill"
                        size="sm"
                        :checked.sync="menu.edit"
                        disabled
                      />
                    </td>
                    <td class="text-center" :id="`setPermisMenuDeleteSwitch-${index}`">
                      <CSwitch
                        v-model="menu.remove"
                        labelOn="on"
                        labelOff="off"
                        color="danger"
                        shape="pill"
                        size="sm"
                        :checked.sync="menu.remove"
                        disabled
                      />
                    </td>
                    <td class="text-center" :id="`setPermisMenuExportSwitch-${index}`">
                      <CSwitch
                        v-model="menu.export"
                        labelOn="on"
                        labelOff="off"
                        color="info"
                        shape="pill"
                        size="sm"
                        :checked.sync="menu.export"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered" v-else id="setPermisTableNoMenu">
                <thead id="setPermisTableNoMenuHead">
                  <tr id="setPermisTableNoMenuHeaderRow">
                    <!-- <th style="font-weight: normal; font-size: 15px;">{{ $t('menu') }}</th> -->
                    <th class="text-dark" ></th>
                    <th class="text-center" id="setPermisTableNoMenuHeaderShow">
                      <h6
                        class="text-success mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('show') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableNoMenuHeaderAddEdit">
                      <h6
                        class="text-warning mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('add') }}/{{ $t('edit') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableNoMenuHeaderDelete">
                      <h6
                        class="text-danger mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('delete') }}
                      </h6>
                    </th>
                    <th class="text-center" id="setPermisTableNoMenuHeaderExport">
                      <h6
                        class="text-info mb-0"
                        style="font-size: 12px;font-weight: normal;"
                      >
                        {{ $t('export') }}
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody id="setPermisTableNoMenuBody">
                  <tr v-if="item.lock === false" id="setPermisTableNoMenuRow">
                    <td class="text-left text-dark" id="setPermisTableNoMenuLabel">
                      {{ $t(item.label + 'Menu') }}
                    </td>
                    <td class="text-center" id="setPermisTableNoMenuShowSwitch">
                      <CSwitch
                        v-model="item.read"
                        labelOn="on"
                        labelOff="off"
                        color="success"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.read"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableNoMenuAddEditSwitch">
                      <CSwitch
                        v-model="item.edit"
                        labelOn="on"
                        labelOff="off"
                        color="warning"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.edit"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableNoMenuDeleteSwitch">
                      <CSwitch
                        v-model="item.remove"
                        labelOn="on"
                        labelOff="off"
                        color="danger"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.remove"
                        disabled
                      />
                    </td>
                    <td class="text-center" id="setPermisTableNoMenuExportSwitch">
                      <CSwitch
                        v-model="item.export"
                        labelOn="on"
                        labelOff="off"
                        color="info"
                        shape="pill"
                        size="sm"
                        :checked.sync="item.export"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CTab>
        </template>
      </CTabs>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  created(){
    // console.log(this.permission)
  },
  props: ['permission'],
  data() {
    return {
      icons: [
        '/img/icon/report.png',
        '/img/icon/sale.png',
        '/img/icon/invoice.png',
        '/img/icon/analytics.png',
        '/img/icon/device.png',
        '/img/icon/inventory.png',
        '/img/icon/product.png',
        '/img/icon/teamwork.png',
        '/img/icon/qr-code-scan.png',
        '/img/icon/settings.png'
      ]
    };
  },
  methods: {
    getImageSrc(index) {
      if (index < this.icons.length) {
        return this.icons[index]
      } else {
        return this.icons[0]
      }
    }
  }
}
</script>
